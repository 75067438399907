@import url('https://fonts.googleapis.com/css2?family=Karla&family=Lora&family=Nunito+Sans:opsz@6..12&family=Raleway:wght@300;500;800&display=swap');

.services-container {
  background-color: #B2BEB5;
  font-size: 18px;
  line-height: 1.7;
  font-family: 'Raleway', sans-serif;
}

.services-title-col {
  padding: 15px;
}

.services-title-col h1 {
  font-size: 45px;
}

.intro-row {
  text-align: center;
  border: 3px solid rgb(241, 210, 172);
  margin: 0px 60px 20px 60px !important;
  background-color: whitesmoke;
}

.intro-col {
  padding: 10px;
}

.monthly-row {
  text-align: center;
  border: 3px solid rgb(241, 210, 172);
  margin: 0px 60px 20px 60px !important;
  background-color: whitesmoke;
}

.monthly-col {
  padding-top: 20px;
}

.monthly-col p{
  margin-top: 15px;
}

.package-row {
  text-align: center;
  margin: 0px 60px 20px 60px !important;
}

.three-col {
  border: 3px solid rgb(241, 210, 172);
  padding-top: 20px;
  /* margin-right: 10px; */
  background-color: whitesmoke;
}

.three-col p {
  margin-top: 15px;
}

.six-col {
  border: 3px solid rgb(241, 210, 172);
  padding-top: 20px;
  margin-left: 10px;
  background-color: whitesmoke;
}

.six-col p {
  margin-top: 15px;
}

.twelve-row {
  text-align: center;
  border: 3px solid rgb(241, 210, 172);
  margin: 0px 60px 20px 60px !important;
  background-color: whitesmoke;
}

.twelve-col {
  padding-top: 20px;
}

.description-row {
  text-align: center;
  border: 3px solid rgb(241, 210, 172);
  margin: 0px 60px 20px 60px !important;
  background-color: whitesmoke;
}

.description-col {
  padding-top: 20px;
}

.add-title {
  text-align: center;
  border: 3px solid rgb(241, 210, 172);
  margin: 0px 60px 10px 60px !important;
  background-color: whitesmoke;
}

.add-row {
  text-align: center;
  margin: 0px 60px 20px 60px !important;
}

.add-col-one {
  background-color: whitesmoke;
  border: 3px solid rgb(241, 210, 172);
  margin-right: 10px;
  padding: 15px;
}

.add-col-two {
  background-color: whitesmoke;
  border: 3px solid rgb(241, 210, 172);
  /* margin-left: 10px; */
  padding: 15px;
}

.book-col {
  text-align: center;
  padding: 20px;
  padding-top: 0px;
}

.book-button {
  border-radius: 7px;
  padding: 10px;
  background-color: whitesmoke;
  border-color: rgb(241, 210, 172);
  border-width: 4px;
  font-size: 24px;
}

@media (max-width: 767px) {
  .six-col {
    margin-top: 20px !important;
    margin-left: 0px;
  }

  .intro-col {
    padding: 10px 20px !important;
  }

  .add-col-two {
    margin-top: 20px !important;
    margin-left: 0px;
  }

  .services-container .row {
    margin: 0px 20px 20px 20px !important;
  }

  .services-container .book-row {
    margin-bottom: 0px !important;
  }
}