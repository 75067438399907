@import url('https://fonts.googleapis.com/css2?family=Karla&family=Lora&family=Nunito+Sans:opsz@6..12&family=Raleway:wght@300;500;800&display=swap');


.header-container {
  background-color: rgb(251, 240, 227);
  /* padding: 10px; */
  font-size: 20px;
  font-family: 'Raleway', sans-serif;
  box-shadow: inset 0 -3em 3em rgba(234, 229, 229, 0.1),
             0 0  0 3px rgb(241, 210, 172),
             0.3em 0.3em 1em rgba(0,0,0,0.3);
}

.navbar {
  padding: 0 !important; 
}


.navbar-collapse{
justify-content: end;
}

.nav-link {
  margin-left: 80px;
  --bs-nav-link-font-size: 22px;
}

.logo {
  padding-left: 10px;
  --bs-navbar-brand-font-size: 40px;
}

.navbar-brand {
  font-size: 30px;
}

#contact-button {
  padding-right: 30px;
}

.credentials {
  font-size: 16px;
}

@media (max-width: 767px) {
  .nav-link {
    margin-left: 0px;
  }

  .logo {
    padding-left: 0px;
    --bs-navbar-brand-font-size: 28px;
  }
  .credentials {
    font-size: 14px;
  }
}

