@import url('https://fonts.googleapis.com/css2?family=Karla&family=Lora&family=Nunito+Sans:opsz@6..12&family=Raleway:wght@300;500;800&display=swap');
/* 
body {
  font-family: 'Karla', sans-serif;
font-family: 'Lora', serif;
font-family: 'Nunito Sans', sans-serif;
font-family: 'Raleway', sans-serif;
} */

.benefits-container {
  background-color:  #B2BEB5;
  font-size: 20px;
  line-height: 1.7;
  font-family: 'Raleway', sans-serif;
}

.benefits-title {
  text-align: center;
  padding: 15px;
}

.benefits-title h1 {
  font-size: 45px;
}

.first-row {
  margin: 0px 40px 0px 40px !important;
  border: 3px solid rgb(241, 210, 172);
}

.first-row-col {
  /* margin: 10px 20px 10px 20px;  */
  background-color: whitesmoke;
  padding: 20px;
}

.second-row {
  margin: 20px 40px 20px 40px !important;
  /* padding: 20px;  */
}

.second-col {
  text-align: center;
  display: flex;
  align-items: center;
  /* background-color: antiquewhite; */
  background-color: rgb(251, 240, 227);
  padding: 20px 20px 10px 20px !important;
}

.third-col {
  padding: 40px;
  background-color: whitesmoke;
}

.third-row {
  background-color: whitesmoke;
  padding: 10px;
  display: flex;
  justify-content: center;
  margin: 0px 50px 0px 50px;
  border: 3px solid rgb(241, 210, 172);
}

.fourth-row {
  margin: 20px 40px 0px 40px !important;
  background-color: whitesmoke;
}

.fifth-col {
  background-color: whitesmoke;
  padding: 40px !important;
  display: flex;
  align-items: center;
  text-align: center;
}

.sixth-col {
  /* background-color: antiquewhite; */
  padding: 0px !important;
  margin: 0px 0px 0px .1px !important;
}
.first-image {
  width: 100%;
  height: 100%;
 }

.second-image {
 width: 100%;
 height: 100%;
}

.fifth-row {
  margin: 20px 40px 0px 40px !important;
}

.seventh-col {
  background-color: antiquewhite;
  padding: 0px !important;
  /* margin: 20px 0px 20px 20px !important; */
}

.eighth-col {
  /* background-color: whitesmoke; */
  background-color: rgb(251, 240, 227);
  padding: 40px !important;
  display: flex;
  align-items: center;
  text-align: center;
  /* margin: 20px 20px 20px 0px !important; */
}

.sixth-row {
  margin: 20px 40px !important;
}
.focus-areas {
  display: grid;
  justify-content: center;
  background-color: whitesmoke;
  padding: 20px;
}
.focus-areas h3 {
  text-align: center;
}

.seventh-row {
  background-color: rgb(251, 240, 227);
  margin: 0px 40px !important;
  padding: 20px;
  border: 3px solid rgb(241, 210, 172);
}

.seventh-row h3 {
  font-size: 24px;
}

.benefits-button-col {
  text-align: center;
  padding: 20px;
}

.benefits-button {
  border-radius: 7px;
  padding: 10px;
  background-color: whitesmoke;
  border-color: rgb(241, 210, 172);
  border-width: 4px;
}

@media (max-width: 767px) {
  .benefits-container {
    font-size: 16px;
  }

  .benefits-container h3 {
    font-size: 20px;
  }

  .benefits-container h1 {
    font-size: 35px;
  }

  .benefits-container .row {
    margin: 0px 20px 20px 20px !important;
  }

  .benefits-container .benefits-button-row {
    margin: 0px 20px 0px 20px !important;
  }

  .third-row {
    margin: 0px;
  }
}