@import url('https://fonts.googleapis.com/css2?family=Pacifico&family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Karla&family=Lora&family=Nunito+Sans:opsz@6..12&family=Raleway:wght@300;500;800&display=swap');

.homepage-container {
  min-height: 100vh;
}


.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-content {
  background-color: rgba(189, 175, 143, 0.607);
  /* margin-top: 20%; */
  /* margin-top: 40px; */
  /* margin-right: 0px; */
  /* margin-left: 100px; */
  /* width: 300px; */
  padding: 15px;
  position: absolute;
  left: 5%;
  top: 30%;
  border: 3px solid rgb(241, 210, 172);
  border-radius: 6px;
  /* transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
             0 0  0 3px rgb(190, 190, 190),
             0.3em 0.3em 1em rgba(0,0,0,0.3); */
  font-size: 28px;
  font-weight: 500;
  color: whitesmoke;
  text-align: center;
  line-height: 1.5;
  font-family: 'Raleway', sans-serif;
} 

.step-button {
  background-color: transparent;
  padding: 0px 10px 3px 10px;
  margin-top: 10px;
  font-size: 40px;
  /* border: 2px; */
  /* box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
             0 0  0 3px rgb(190, 190, 190),
             0.3em 0.3em 1em rgba(0,0,0,0.3); */
  border-radius: 10px;
  /* border-color: whitesmoke; */
  border: 3px solid rgb(241, 210, 172);
  /* font-family: 'Pacifico', cursive; */
  font-family: 'Sacramento', cursive;
  font-weight: 500;
  font-size: 60px;
  color: whitesmoke;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 7px;
}

.video-content p{
  margin-bottom: 0px;
}

@media (max-width: 767px) {
  .video-content {
    max-width: 280px;
    font-size: 22px;
    /* top: 30%;
    left: 20% */
  }
  .step-button {
    font-size: 46px;
  }
}