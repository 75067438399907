@import url('https://fonts.googleapis.com/css2?family=Karla&family=Lora&family=Nunito+Sans:opsz@6..12&family=Raleway:wght@300;500;800&display=swap');


.about-container {
  background-color:  #B2BEB5;
  font-size: 20px;
  line-height: 1.7;
  font-family: 'Raleway', sans-serif;
}

.about-title {
  padding: 15px;
}

.about-title h1 {
  font-size: 45px;
}

.about-row{
  margin: 0px 60px 20px 60px !important;
}

.image-col {
  background-color: antiquewhite;
  height: 450px;
  /* padding: 0px !important; */
  display: flex;
  justify-content: center;
}

.mpa-image {
  width: 90%;
  height: 100%;
}

.about-col {
  background-color: whitesmoke;
  padding: 20px 40px !important;
  text-align: center;
  display: flex;
  align-items: center;
}

.more-info-row {
  margin: 0px 60px 0px 60px !important;
  background-color: whitesmoke;
  border: 3px solid rgb(241, 210, 172);
}

.more-info-col {
  padding: 40px 60px !important;
}

.iwa {
  display: flex;
  justify-content: center;
  margin-top: 20px !important;
}

.iwa-image {
  border: 3px solid rgb(241, 210, 172);
}

.empty {
  min-height: 20px;
}

@media (max-width: 767px) {
  .about-container {
    font-size: 16px;
  }

  .about-row {
    margin: 0px 20px 20px 20px !important;
  }

  .more-info-row {
    margin: 0px 20px 0px 20px !important;
  }

  .more-info-col {
    padding: 25px !important;
  }
  
  .mpa-image {
    width: 100%;
  }

  .image-col {
    padding: 0px !important;
    height: 400px;
  }

  .about-col {
    padding: 50px !important;
    height: 250px;
  }
}